
export const FETCH_PLANS_REQUEST = 'FETCH_PLANS_REQUEST';
export const FETCH_PLANS_SUCCESS = 'FETCH_PLANS_SUCCESS';
export const FETCH_PLANS_FAILURE = 'FETCH_PLANS_FAILURE';


  
  function requestFetchPlans() {
    return {
      type: FETCH_PLANS_REQUEST,
      isFetching: true,
    };
  }
  
  function fetchPlansSuccess(plans) {
    return {
      type: FETCH_PLANS_SUCCESS,
      isFetching: false,
      plans,
    };
  }
  
  function fetchPlansError(message) {
    return {
      type: FETCH_PLANS_FAILURE,
      isFetching: false,
      message,
    };
  }

  
  export function fetchPlans() {
    const config = {
      method: 'GET',
      
    };
  
    return dispatch => {
      dispatch(requestFetchPlans());
  
      return fetch(process.env.REACT_APP_API_ENDPOINT+'/service-plans-all')
        .then(response =>
          response.json().then(responseJson => ({
            plans: responseJson.plans,
            responseJson,
          })),
        )
        .then(({ plans, responseJson }) => {
          if (!responseJson.plans) {
            // If there was a problem, we want to
            //  error condition
            dispatch(fetchPlansError(plans.message));
            return Promise.reject(plans);
          }          
          //console.log(services);
          // success action
          dispatch(fetchPlansSuccess(plans));
          return Promise.resolve(plans);
        })
        .catch(err => console.error('Error: ', err));
    };
  }
  