export const FETCH_SERVICECATLOG_REQUEST = 'FETCH_SERVICECATLOG_REQUEST';
export const FETCH_SERVICECATLOG_SUCCESS = 'FETCH_SERVICECATLOG_SUCCESS';
export const FETCH_SERVICECATLOG_FAILURE = 'FETCH_SERVICECATLOG_FAILURE';


  
  function requestFetchServicesCatlog() {
    return {
      type: FETCH_SERVICECATLOG_REQUEST,
      isFetching: true,
    };
  }
  
  function fetchServicesCatlogSuccess(services) {
    return {
      type: FETCH_SERVICECATLOG_SUCCESS,
      isFetching: false,
      services,
    };
  }
  
  function fetchServicesCatlogError(message) {
    return {
      type: FETCH_SERVICECATLOG_FAILURE,
      isFetching: false,
      message,
    };
  }

  
  export function fetchServicesCatlog() {
    const config = {
      method: 'GET',
      
    };
  
    return dispatch => {
      dispatch(requestFetchServicesCatlog());
  
      return fetch(process.env.REACT_APP_API_ENDPOINT+'/service-catalog-all')
        .then(response =>
          response.json().then(responseJson => ({
            services: responseJson.services,
            responseJson,
          })),
        )
        .then(({ services, responseJson }) => {
          if (!responseJson.services) {
            // If there was a problem, we want to
            //  error condition
            dispatch(fetchServicesCatlogError(services.message));
            return Promise.reject(services);
          }          
          console.log(services);
          // success action
          dispatch(fetchServicesCatlogSuccess(services));
          return Promise.resolve(services);
        })
        .catch(err => console.error('Error: ', err));
    };
  }
  