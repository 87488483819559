import React,{Fragment, Component} from 'react';
import {withRouter, Redirect} from 'react-router-dom';
import {Col, Row, Button, Form, FormGroup, Label, Input} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import jwt from 'jsonwebtoken';
import {loginUser} from '../Actions/user';
import digitpLogo from '../../../assets/utils/images/logo.png';
import ReCAPTCHA from 'react-google-recaptcha';

class Login extends Component{
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        isAuthenticated: PropTypes.bool,
        isFetching: PropTypes.bool,
        location: PropTypes.any, 
        errorMessage: PropTypes.string,
    };    
    static defaultProps = {
        isAuthenticated: false,
        isFetching: false,
        location: {},
        errorMessage: null,
    };
    static isAuthenticated(token) {
        // We check if app runs with backend mode
        //if (!config.isBackend && token) return true;
        if (!token) return;
        const date = new Date().getTime() / 1000;
        const data = jwt.decode(token);
        if(!data) return;
        return date < data.exp;
    }
    constructor(props){
        super(props);
        this.state ={
            username : '',
            password: '',
            validationError: ''
        }
        this.recaptchaRef = React.createRef();

    }
    componentDidMount(){
        const token = localStorage.getItem('id_token');
        const date = new Date().getTime() / 1000;
        const data = jwt.decode(token);       
        if(data && date < data.exp){
            this.props.history.push('/apps');
        }
    }
    changeUserName = (e) => {
        this.setState({
            username: e.target.value,
            validationError:''
        })        
    }
    changePassword = (e) =>{
        this.setState({
            password: e.target.value,
            validationError:''
        })
    }
    navigateToDashboard = (e) =>{
        this.setState({
            validationError: ''
        }) 
        const captchaToken = this.recaptchaRef.current.getValue();
        const {username, password} = this.state;
        if(username && password && captchaToken ){
        this.props.dispatch(
            loginUser({
                    username: username,
                    password: password,
                    recaptchaToken:captchaToken
                }),
            ).then((data) => {
                    this.recaptchaRef.current.reset();
                if(data && data.success){
                    this.props.history.push('/apps');
                }else{
                    alert("Wrong Username/Password");
                }                   
            }).catch(e=>{
                this.recaptchaRef.current.reset();
                alert("Wrong Username/Password");
            });
        }else{
            if(captchaToken  === '' && username && password){
                this.setState({
                    validationError: 'Please verify the reCAPTCHA checkbox!'
                })
            }else{
                this.setState({
                    validationError: 'Invalid username or password'
                })    
            }
           
        }
        e.preventDefault();
    }
    render(){
        const {from} = this.props.location.state || {
            from: {pathname: '/apps'},
          };
          const  {validationError} = this.state;
        return (
            <Fragment>
                <div className="h-100 bg-digitap-blue bg-animation">
                    <div className="d-flex h-100 justify-content-center align-items-center">
                        <Col md="8" className="mx-auto app-login-box">
                            <div className="modal-dialog w-100 mx-auto">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <div className="h5 modal-title text-center">
                                            <h4 className="mt-2">
                                                <div>
                                                    <img src={digitpLogo} alt="Digitap.ai" />
                                                </div>
                                                <span>Admin Portal</span>
                                            </h4>
                                        </div>
                                        <Form onSubmit={this.navigateToDashboard}>
                                            <Row form>
                                                <Col md={12}>
                                                    <FormGroup>
                                                            <Input type="text" name="text" placeholder="Username" onChange={(e) => this.changeUserName(e)}></Input>
                                                            {this.state.username === '' && <span style={{color: 'red'}}>{this.state.errormsg}</span>}
                                                            {this.state.errormsg === "Please provide valid mail id or 10 digit phone number" && <span style={{color: 'red'}}>{this.state.errormsg}</span>}
                                                            {this.state.showSendOTPFailureError && <span style={{color: 'red'}}>Error occured while sending OTP. Try Resend</span> }
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row form>
                                                <Col md={12}>
                                                    <FormGroup>
                                                            <Input type="password" name="text" placeholder="Password" onChange={(e) => this.changePassword(e)}></Input>
                                                            {this.state.username === '' && <span style={{color: 'red'}}>{this.state.errormsg}</span>}
                                                            {this.state.errormsg === "Please provide valid mail id or 10 digit phone number" && <span style={{color: 'red'}}>{this.state.errormsg}</span>}
                                                            {this.state.showSendOTPFailureError && <span style={{color: 'red'}}>Error occured while sending OTP. Try Resend</span> }
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row form className="text-center">
                                                <Col></Col>
                                                <Col className="text-center">
                                                    <ReCAPTCHA
                                                    ref={this.recaptchaRef}
                                                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                                     />
                                                </Col>
                                                <Col></Col>
                                            </Row>
                                            <Row className="text-center">
                                                <p className='text-danger m-auto'>{validationError}</p>
                                            </Row>
                                            <Row className="text-center">
                                                <Col className="my-3" >
                                                    <Button type="submit" className="btn-digitap-blue"
                                                     disabled={!this.state.username || !this.state.password } size='lg' block>Login</Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                   
                                </div>
                            </div>
                        </Col>
                    </div>
                </div>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        isFetching: state.auth.isFetching,
        isAuthenticated: state.auth.isAuthenticated,
        errorMessage: state.auth.errorMessage,
    };
}

export default withRouter(connect(mapStateToProps)(Login));