import {BrowserRouter as Router, Route, Redirect} from 'react-router-dom';
import React, {Suspense, lazy, Fragment} from 'react';
import Loader from 'react-loaders'

import {
    ToastContainer,
} from 'react-toastify';
import Login from '../../DemoPages/Applications/Login';
const Applications = lazy(() => import('../../DemoPages/Applications'));
const PrivateRoute = ({dispatch, component, ...rest }) => {
    if (!Login.isAuthenticated(localStorage.getItem('id_token'))) {        
        return (<Redirect to="/sign-in"/>);
    } else {
        return ( 
            <Route {...rest} render={props => (React.createElement(component, props))}/>
        );
    }
};


const AppMain = () => {
    return (
        <Fragment>
             <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse"/>
                        </div>
                        <h6 className="mt-3">
                            Please wait while we load digitap admin portal
                            <small>Digitap.ai pvt ltd</small>
                        </h6>
                    </div>
                </div>
            }>
                <PrivateRoute path="/apps" component={Applications}/>
            </Suspense>            
            <Route path="/sign-in" component={Login}/>            
            <Route exact path="/" render={() => (
                <Redirect to="/sign-in"/>
            )}/>
            <ToastContainer/>            
        </Fragment>
    )
};

export default AppMain;