import { EPERM } from "constants";

export const CREATE_ENTERPRISEACC_INITIAL = 'CREATE_ENTERPRISEACC_INITIAL';
export const CREATE_ENTERPRISEACC_REQUEST = 'CREATE_ENTERPRISEACC_REQUEST';
export const CREATE_ENTERPRISEACC_SUCCESS = 'CREATE_ENTERPRISEACC_SUCCESS';
export const CREATE_ENTERPRISEACC_FAILURE = 'CREATE_ENTERPRISEACC_FAILURE';

export const FETCH_ENTERPRISEACCSLIST_REQUEST = 'FETCH_ENTERPRISEACCSLIST_REQUEST';
export const FETCH_ENTERPRISEACCSLIST_SUCCESS = 'FETCH_ENTERPRISEACCSLIST_SUCCESS';
export const FETCH_ENTERPRISEACCSLIST_FAILURE = 'FETCH_ENTERPRISEACCSLIST_FAILURE';

export const FETCH_ENTERPRISEACCS_REQUEST = 'FETCH_ENTERPRISEACCS_REQUEST';
export const FETCH_ENTERPRISEACCS_SUCCESS = 'FETCH_ENTERPRISEACCS_SUCCESS';
export const FETCH_ENTERPRISEACCS_FAILURE = 'FETCH_ENTERPRISEACCS_FAILURE'; 

export const CREATE_CLIENTKEYID_INITIAL = 'CREATE_CLIENTKEYID_INITIAL';
export const CREATE_CLIENTKEYID_REQUEST = 'CREATE_CLIENTKEYID_REQUEST';
export const CREATE_CLIENTKEYID_SUCCESS = 'CREATE_CLIENTKEYID_SUCCESS';
export const CREATE_CLIENTKEYID_FAILURE = 'CREATE_CLIENTKEYID_FAILURE';

export const UPDATE_ENTERPRISEACC_INITIAL = 'UPDATE_ENTERPRISEACC_INITIAL';
export const UPDATE_ENTERPRISEACC_REQUEST = 'UPDATE_ENTERPRISEACC_REQUEST';
export const UPDATE_ENTERPRISEACC_SUCCESS = 'UPDATE_ENTERPRISEACC_SUCCESS';
export const UPDATE_ENTERPRISEACC_FAILURE = 'UPDATE_ENTERPRISEACC_FAILURE';

export const UPDATE_ENTERPRISEACC_PLAN_SUCCESS = 'UPDATE_ENTERPRISEACC_PLAN_SUCCESS';
export const UPDATE_ENTERPRISEACC_PLAN_FAILURE = 'UPDATE_ENTERPRISEACC_PLAN_FAILURE';

export const FETCH_ENTERPRISE_BILL_REQUEST = 'FETCH_ENTERPRISE_BILL_REQUEST';
export const FETCH_ENTERPRIS_BILL_SUCCESS = 'FETCH_ENTERPRIS_BILL_SUCCESS';
export const FETCH_ENTERPRISE_BILL_FAILURE = 'FETCH_ENTERPRISE_BILL_FAILURE';
function createEnterpriseAccInitial() {
    return {
      type: CREATE_ENTERPRISEACC_INITIAL,
      isFetching: false,
    };
  }
  
  function requestCreateEnterpriseAcc(enterpriseAcc) {
    return {
      type: CREATE_ENTERPRISEACC_REQUEST,
      isFetching: true,
      enterpriseAcc,
    };
  }
  
  function createEnterpriseAccSuccess(enterpriseAcc) {
    return {
      type: CREATE_ENTERPRISEACC_SUCCESS,
      isFetching: false,
      enterpriseAcc,
    };
  }
  
  function createEnterpriseAccError(message) {
    return {
      type: CREATE_ENTERPRISEACC_FAILURE,
      isFetching: false,
      message,
    };
  }
  
  //enterprise acc list 
  function requestFetchEnterpriseAccsList() {
    return {
      type: FETCH_ENTERPRISEACCSLIST_REQUEST,
      isFetching: true,
    };
  }
  
  function fetchEnterpriseAccsListSuccess(enterpriseAccs) {
    return {
      type: FETCH_ENTERPRISEACCSLIST_SUCCESS,
      isFetching: false,
      enterpriseAccs,
    };
  }
  
  
  function fetchEnterpriseAccsListError(message) {
    return {
      type: FETCH_ENTERPRISEACCSLIST_FAILURE,
      isFetching: false,
      message,
    };
  }
///fetch single acc 
  function requestFetchEnterpriseAccs() {
    return {
      type: FETCH_ENTERPRISEACCS_REQUEST,
      isFetching: true,
    };
  }
  
  function fetchEnterpriseAccsSuccess(enterpriseAcc) {
    return {
      type: FETCH_ENTERPRISEACCS_SUCCESS,
      isFetching: false,
      enterpriseAcc,
    };
  }
  
  function fetchEnterpriseAccsError(message) {
    return {
      type: FETCH_ENTERPRISEACCS_FAILURE,
      isFetching: false,
      message,
    };
  }
//create client KEY and ID 

function createClientKeyIdInitial() {
  return {
    type: CREATE_CLIENTKEYID_INITIAL,
    isFetching: false,
  };
}

function requestClientKeyId(enterpriseAcc) {
  return {
    type: CREATE_CLIENTKEYID_REQUEST,
    isFetching: true,
    enterpriseAcc,
  };
}

function createClientKeyIdSuccess(enterpriseAcc) {
  return {
    type: CREATE_CLIENTKEYID_SUCCESS,
    isFetching: false,
    enterpriseAcc,
  };
}

function createClientKeyIdError(message) {
  return {
    type: CREATE_CLIENTKEYID_FAILURE,
    isFetching: false,
    message,
  };
}
//update enterprise acc 
function updateEnterpriseAccInitial() {
  return {
    type: UPDATE_ENTERPRISEACC_INITIAL,
    isFetching: false,
  };
}

function requestUpdateEnterpriseAcc(enterpriseAcc) {
  return {
    type: UPDATE_ENTERPRISEACC_REQUEST,
    isFetching: true,
    enterpriseAcc,
  };
}

function updateEnterpriseAccSuccess(enterpriseAcc) {
  return {
    type: UPDATE_ENTERPRISEACC_SUCCESS,
    isFetching: false,
    enterpriseAcc,
  };
}

function updateEnterpriseAccError(message) {
  return {
    type: UPDATE_ENTERPRISEACC_FAILURE,
    isFetching: false,
    message,
  };
}
//update plan actions 
function updateEnterpriseAccPlanSuccess(message){
  return {
    type: UPDATE_ENTERPRISEACC_PLAN_SUCCESS,
    isFetching: false,
    message,
  };
}
function updateEnterpriseAccPlanError(message){
  return {
    type: UPDATE_ENTERPRISEACC_PLAN_FAILURE,
    isFetching: false,
    message,
  };
}

//fect ent bills
//fetch single acc 
function requestFetchEnterpriseBills() {
  return {
    type: FETCH_ENTERPRISE_BILL_REQUEST,
    isFetching: true,
  };
}

function fetchEnterpriseBillsSuccess(entBills) {
  return {
    type: FETCH_ENTERPRIS_BILL_SUCCESS,
    isFetching: false,
    entBills,
  };
}

function fetchEnterpriseBillsError(message) {
  return {
    type: FETCH_ENTERPRISE_BILL_FAILURE,
    isFetching: false,
    message,
  };
}

  export function createEnterpriseAcc(postData) {
    const config = {     
      method: 'POST',      
      body: JSON.stringify(postData),      
    };
  
    return dispatch => {      
      dispatch(requestCreateEnterpriseAcc(postData));
      //console.log(config);
      //if(process.env.NODE_ENV === "development") {
      return fetch(process.env.REACT_APP_API_ENDPOINT+'/enterprise-acc', config)
      
         .then(response => response.json()
         .then(responseJson => ({ enterpriseAccId:responseJson.enterpriseAccId,
           responseJson, })),)
       
         .then(({ data, responseJson }) => {
          if (!responseJson.success) {
            dispatch(createEnterpriseAccError(responseJson.message));
            return Promise.reject(responseJson);
          }
          dispatch(createEnterpriseAccSuccess(responseJson));
          setTimeout(() => {
            dispatch(createEnterpriseAccInitial());
          }, 5000);
          return Promise.resolve(responseJson);
        })
        .catch(err => console.error('Error: ', err));
    //   } else {
    //     dispatch(createPostError(''));
    //     return Promise.reject();
    //   }
    
  

      
    };
  }
  //fetch list of ent accs 
  export function fetchEnterpriseAccsList() {
    const config = {
      method: 'GET',    
    };

    return dispatch => {
      dispatch(requestFetchEnterpriseAccsList());
  
      return fetch(process.env.REACT_APP_API_ENDPOINT+'/enterprise-acc')
        .then(response =>
          response.json().then(responseJson => ({
            enterpriseAccs: responseJson.enterpriseAccs,
            responseJson,
          })),
        )
        .then(({ enterpriseAccs, responseJson }) => {
          if (!responseJson.enterpriseAccs) {
            // If there was a problem, we want to
            //  error condition
            dispatch(fetchEnterpriseAccsListError(enterpriseAccs.message));
            return Promise.reject(enterpriseAccs);
          }          
          //console.log(enterpriseAccs);
          // success action
          dispatch(fetchEnterpriseAccsListSuccess(enterpriseAccs));
          return Promise.resolve(enterpriseAccs);
        })
        .catch(err => console.error('Error: ', err));
    };
  }

  //fetch single ent acc
export function fetchEnterpriseAcc(entId){
  const config = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({"entId":entId}),
    
  };
  return dispatch => {
    dispatch(requestFetchEnterpriseAccs());

    return fetch(process.env.REACT_APP_API_ENDPOINT+`/fetch-ent?entId=${entId}`)
      .then(response =>
        response.json().then(responseJson => ({
          enterpriseAcc: responseJson.enterprise_acc,
          responseJson,
        })),
      )
      .then(({ enterpriseAcc, responseJson }) => {
        if (!responseJson.enterprise_acc) {
          // If there was a problem, we want to
          //  error condition
          dispatch(fetchEnterpriseAccsError(enterpriseAcc.message));
          return Promise.reject(enterpriseAcc);
        }          
        //console.log(enterpriseAcc);
        // success action
        dispatch(fetchEnterpriseAccsSuccess(enterpriseAcc));
        return Promise.resolve(enterpriseAcc);
      })
      .catch(err => console.error('Error: ', err));
  };
}

export function createClientKey(postdata){
  const config = {     
    method: 'POST',    
    body: JSON.stringify(postdata),    
  };
  return dispatch => {      
    dispatch(requestClientKeyId(postdata)); 
    //https://cr1ky7h5gj.execute-api.ap-south-1.amazonaws.com/dev/create-client-id-key    nodejs  https://apistage.digitap.work/v1   
    return fetch(process.env.REACT_APP_API_ENDPOINT+'/create-client-key', config)    
       .then(response => response.json()
       .then(responseJson => ({ enterpriseAccId:responseJson.enterpriseAccId,
         responseJson, })),)     
       .then(({ data, responseJson }) => {
        if (!responseJson.success) {
          dispatch(createClientKeyIdError(responseJson.message));
          return Promise.reject(responseJson);
        }
        dispatch(createClientKeyIdSuccess(responseJson));
        setTimeout(() => {
          dispatch(createClientKeyIdInitial());
        }, 5000);
        return Promise.resolve(responseJson);
      })
      .catch(err => console.error('Error: ', err));   
  };
}

export function reGenerateApiKey(postdata){
  const config = {     
    method: 'POST',    
    body: JSON.stringify(postdata),    
  };
  return dispatch => {      
    dispatch(requestClientKeyId(postdata)); 
    //https://cr1ky7h5gj.execute-api.ap-south-1.amazonaws.com/dev/create-client-id-key    nodejs   
    return fetch(process.env.REACT_APP_API_ENDPOINT+'/regenerate-client-key', config)    
       .then(response => response.json()
       .then(responseJson => ({ enterpriseAccId:responseJson.enterpriseAccId,
         responseJson, })),)     
       .then(({ data, responseJson }) => {
        if (responseJson.status != 200 ) {
          dispatch(createClientKeyIdError(responseJson.message));
          return Promise.reject(responseJson);
        }
        dispatch(createClientKeyIdSuccess(responseJson));
        setTimeout(() => {
          dispatch(createClientKeyIdInitial());
        }, 5000);
        return Promise.resolve(responseJson);
      })
      .catch(err => console.error('Error: ', err));   
  };
}

export function updateEnterpriseAcc(postData){
  const config = {     
    method: 'POST',      
    body: JSON.stringify(postData),      
  };

  return dispatch => {      
    dispatch(requestUpdateEnterpriseAcc(postData));    
    return fetch(process.env.REACT_APP_API_ENDPOINT+'/update-ent', config)    
       .then(response => response.json()
       .then(responseJson => ({ enterpriseAccId:responseJson.enterpriseAccId,
         responseJson, })),)     
       .then(({ data, responseJson }) => {
        if (!responseJson.success) {
          dispatch(updateEnterpriseAccError(responseJson.message));
          return Promise.reject(responseJson);
        }
        dispatch(updateEnterpriseAccSuccess(responseJson));
        setTimeout(() => {
          dispatch(updateEnterpriseAccInitial());
        }, 5000);
        return Promise.resolve(responseJson);
      })
      .catch(err => console.error('Error: ', err));    
  };
}

export function updateEnterprisePlan(postData){
  const config = {     
    method: 'POST',      
    body: JSON.stringify(postData),      
  };
  return dispatch => {     
       
    return fetch('https://hoto9ztgmf.execute-api.ap-south-1.amazonaws.com/dev/change-ent-plan', config)    
       .then(response => response.json()
       .then(responseJson => ({ enterpriseAccId:responseJson.enterpriseAccId,
         responseJson, })),)     
       .then(({ data, responseJson }) => {
        if (!responseJson.success) {
          dispatch(updateEnterpriseAccPlanError(responseJson.message));
          return Promise.reject(responseJson);
        }
        dispatch(updateEnterpriseAccPlanSuccess(responseJson));        
        return Promise.resolve(responseJson);
      })
      .catch(err => console.error('Error: ', err));    
  };
}

export function fetchEnterpriseBills(entId){
  const config = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({"entId":entId}),
    
  };
  return dispatch => {
    dispatch(requestFetchEnterpriseBills());

    return fetch(process.env.REACT_APP_API_ENDPOINT+`/admin-ent-bills?entId=${entId}`)
      .then(response =>
        response.json().then(responseJson => ({
          bills: responseJson.bills,
          responseJson,
        })),
      )
      .then(({ bills, responseJson }) => {
        if (!responseJson.bills) {
          // If there was a problem, we want to
          //  error condition
          dispatch(fetchEnterpriseBillsError(responseJson.message));
          return Promise.reject(responseJson);
        }          
        //console.log(enterpriseAcc);
        // success action
        dispatch(fetchEnterpriseBillsSuccess(bills));
        return Promise.resolve(bills);
      })
      .catch(err => console.error('Error: ', err));
  };
}

export const UPDATE_SERVICERATE_REQUEST = 'UPDATE_SERVICERATE_REQUEST';
export const UPDATE_SERVICERATE_SUCCESS = 'UPDATE_SERVICERATE_SUCCESS';
export const UPDATE_SERVICERATE_FAILURE = 'UPDATE_SERVICERATE_FAILURE';
export const UPDATE_SERVICERATE_INITIAL = 'UPDATE_SERVICERATE_INITIAL';
function updateServiceRateInitial(){
  return {
    type: UPDATE_SERVICERATE_INITIAL,
    updatingServiceRate: false,
    updateServiceRateErrorMessage:'',
    updatedServicerate:false
  };
}
function updateServiceRateRequest(){
  return {
    type: UPDATE_SERVICERATE_REQUEST,
    updatingServiceRate: true,
    updateServiceRateErrorMessage:'',
    updatedServicerate:false
  };
}
function updateServiceRateSuccess(){
  return {
    type: UPDATE_SERVICERATE_SUCCESS,
    updatingServiceRate: false,
    updateServiceRateErrorMessage:'',
    updatedServicerate:true
  };
}
function updateServiceRateFailure(message){
  return {
    type: UPDATE_SERVICERATE_FAILURE,
    updatingServiceRate: false,
    updateServiceRateErrorMessage:message,
    updatedServicerate:false
  };
}
export function updateServicerateEnt(postdata){
  const config = {     
    method: 'POST',    
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization:localStorage.getItem('id_token')
    },
    body: JSON.stringify(postdata),    
  };
  return dispatch => {      
    dispatch(updateServiceRateRequest(postdata));       
    return fetch(process.env.REACT_APP_API_ENDPOINT+'/update-service-rate', config)    
       .then(response => response.json()
       .then(responseJson => ({ enterpriseAccId:responseJson.enterpriseAccId,
         responseJson, })),)     
       .then(({ data, responseJson }) => {
        if (responseJson.code !== '200') {
          dispatch(updateServiceRateFailure(responseJson.model.message));
          return Promise.reject(responseJson);
        }
        dispatch(updateServiceRateSuccess(responseJson));
        setTimeout(() => {
          dispatch(updateServiceRateInitial());
        }, 5000);
        return Promise.resolve(responseJson);
      })
      .catch(err => console.error('Error: ', err));   
  };
}
export const UPDATE_CREDIT_PERIOD_REQUEST = 'UPDATE_CREDIT_PERIOD_REQUEST';
export const UPDATE_CREDIT_PERIOD_INITIAL = 'UPDATE_CREDIT_PERIOD_INITIAL';
export const UPDATE_CREDIT_PERIOD_SUCCESS = 'UPDATE_CREDIT_PERIOD_SUCCESS';
export const UPDATE_CREDIT_PERIOD_FAILURE = 'UPDATE_CREDIT_PERIOD_FAILURE';
function updateCreditPeriodIntital(){
  return {
    type: UPDATE_CREDIT_PERIOD_INITIAL,
    updatingCreditPeriod: false,
    updateCredtiPeriodEM:'',
    updatedCreditPeriod:false
  };
}
function updateCreditPeriodRequest(){
  return {
    type: UPDATE_SERVICERATE_FAILURE,
    updatingCreditPeriod: true,
    updateCredtiPeriodEM:'',
    updatedCreditPeriod:false
  };
}
function updateCreditPeriodSuccess(data){
  return {
    type: UPDATE_CREDIT_PERIOD_SUCCESS,
    updatingCreditPeriod: false,
    updateCredtiPeriodEM:'',
    updatedCreditPeriod:true
  };
}
function updateCreditPeriodFailure(message){
  return {
    type: UPDATE_CREDIT_PERIOD_FAILURE,
    updatingCreditPeriod: false,
    updateCredtiPeriodEM:message,
    updatedCreditPeriod:false
  };
}



export function updateEntBillDuePeriod(postdata){
  const config = {     
    method: 'POST',    
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization:localStorage.getItem('id_token')
    },
    body: JSON.stringify(postdata),    
  };
  return dispatch => {      
    dispatch(updateCreditPeriodRequest(postdata));       
    return fetch(process.env.REACT_APP_API_ENDPOINT+'/update-credit-period', config)    
       .then(response => response.json()
       .then(responseJson => ({ enterpriseAccId:responseJson.enterpriseAccId,
         responseJson, })),)     
       .then(({ data, responseJson }) => {
        if (responseJson.code !== '200') {
          dispatch(updateCreditPeriodFailure(responseJson.model.message));
          return Promise.reject(responseJson);
        }
        dispatch(updateCreditPeriodSuccess(responseJson));
        setTimeout(() => {
          dispatch(updateCreditPeriodIntital());
        }, 5000);
        return Promise.resolve(responseJson);
      })
      .catch(err => console.error('Error: ', err));   
  };
}


export const UPDATE_SERVICE_STATUS_REQUEST = 'UPDATE_SERVICE_STATUS_REQUEST';
export const UPDATE_SERVICE_STATUS_INITIAL = 'UPDATE_SERVICE_STATUS_INITIAL';
export const UPDATE_SERVICE_STATUS_SUCCESS = 'UPDATE_SERVICE_STATUS_SUCCESS';
export const UPDATE_SERVICE_STATUS_FAILURE = 'UPDATE_SERVICE_STATUS_FAILURE';
function updateServiceStatusInitial(){
  return {
    type: UPDATE_SERVICE_STATUS_INITIAL,
    updatingServiceStatus: false,
    updateServiceStatusErrorMessage:'',
    updatedServiceStatus:false
  };
}
function updateServiceStatusRequest(){
  return {
    type: UPDATE_SERVICE_STATUS_REQUEST,
    updatingServiceStatus: true,
    updateServiceStatusErrorMessage:'',
    updatedServiceStatus:false
  };
}
function updateServiceStatusSuccess(data){
  return {
    type: UPDATE_SERVICE_STATUS_SUCCESS,
    updatingServiceStatus: false,
    updateServiceStatusErrorMessage:'',
    updatedServiceStatus:true
  };
}
function updateServiceStatusFailure(message){
  return {
    type: UPDATE_SERVICE_STATUS_FAILURE,
    updatingServiceStatus: false,
    updateServiceStatusErrorMessage:message,
    updatedServiceStatus:true
  };
}

export function doEnableOrDisableAction(postData){
  const config = {     
    method: 'POST',    
    headers: {          
      authorization: `Bearer ${localStorage.getItem('id_token')}`
    },
    body: JSON.stringify(postData),    
  };
  return dispatch => {      
    dispatch(updateServiceStatusRequest());       
    return fetch(process.env.REACT_APP_API_ENDPOINT+'/admin/ent-service-status-update', config)    
       .then(response => response.json())          
       .then(responseJson => {
        if (responseJson.code !== '200') {
          dispatch(updateServiceStatusFailure(responseJson.model.message));
          return Promise.reject(responseJson);
        }
        dispatch(updateServiceStatusSuccess(responseJson));
      
        return Promise.resolve(responseJson);
      })
      .catch(err => console.error('Error: ', err));   
  };
}