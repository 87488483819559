import { combineReducers } from 'redux';
import ThemeOptions from './ThemeOptions';
import enterpriseAccs  from './enterpriseAccs';
import auth from './auth'
import serviceCatlog from './serviceReducers';
import uploadDocsReducers from './uploadDocsReducers';
import plan from './plansReducer';
export default combineReducers({
    ThemeOptions,
    enterpriseAccs,
    auth,
    serviceCatlog,
    uploadDocsReducers,
    plan
});