import {
   
    FETCH_PLANS_REQUEST,
    FETCH_PLANS_SUCCESS,
    FETCH_PLANS_FAILURE
  } from '../DemoPages/Applications/Actions/plansActions';
  export default function plan(
    state = {
      isFetching: false,
    },
    action,
  ) {
    switch (action.type) {      
      case FETCH_PLANS_REQUEST:
        return Object.assign({}, state, {
          isFetching: true,
        });
      case FETCH_PLANS_SUCCESS:
        return Object.assign({}, state, {
          isFetching: false,
          plans: action.plans,
        });
      case FETCH_PLANS_FAILURE:
        return Object.assign({}, state, {
          isFetching: false,
          message: 'Something wrong happened. Please come back later',
        });
      default:
        return state;
    }
  }
  

  