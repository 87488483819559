import {
   
    FETCH_SERVICECATLOG_REQUEST,
    FETCH_SERVICECATLOG_SUCCESS,
    FETCH_SERVICECATLOG_FAILURE
  } from '../DemoPages/Applications/Actions/serviceActions';
  export default function serviceCatlog(
    state = {
      isFetching: false,
    },
    action,
  ) {
    switch (action.type) {      
      case FETCH_SERVICECATLOG_REQUEST:
        return Object.assign({}, state, {
          isFetching: true,
        });
      case FETCH_SERVICECATLOG_SUCCESS:
        return Object.assign({}, state, {
          isFetching: false,
          services: action.services,
        });
      case FETCH_SERVICECATLOG_FAILURE:
        return Object.assign({}, state, {
          isFetching: false,
          message: 'Something wrong happened. Please come back later',
        });
      default:
        return state;
    }
  }
  