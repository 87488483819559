import {
    CREATE_ENTERPRISEACC_INITIAL,
    CREATE_ENTERPRISEACC_REQUEST,
    CREATE_ENTERPRISEACC_SUCCESS,
    CREATE_ENTERPRISEACC_FAILURE,
    FETCH_ENTERPRISEACCSLIST_REQUEST,
    FETCH_ENTERPRISEACCSLIST_SUCCESS,
    FETCH_ENTERPRISEACCSLIST_FAILURE,
    FETCH_ENTERPRISEACCS_REQUEST,
    FETCH_ENTERPRISEACCS_SUCCESS,
    FETCH_ENTERPRISEACCS_FAILURE,
    CREATE_CLIENTKEYID_INITIAL,
    CREATE_CLIENTKEYID_REQUEST,
    CREATE_CLIENTKEYID_SUCCESS,
    CREATE_CLIENTKEYID_FAILURE,
    UPDATE_ENTERPRISEACC_INITIAL,
    UPDATE_ENTERPRISEACC_REQUEST,
    UPDATE_ENTERPRISEACC_SUCCESS,
    UPDATE_ENTERPRISEACC_FAILURE,
    UPDATE_ENTERPRISEACC_PLAN_SUCCESS,
    UPDATE_ENTERPRISEACC_PLAN_FAILURE,
    FETCH_ENTERPRISE_BILL_REQUEST,
    FETCH_ENTERPRIS_BILL_SUCCESS,
    FETCH_ENTERPRISE_BILL_FAILURE, 
    UPDATE_SERVICERATE_REQUEST,
    UPDATE_SERVICERATE_FAILURE,
    UPDATE_SERVICERATE_SUCCESS,
    UPDATE_SERVICERATE_INITIAL,
    UPDATE_CREDIT_PERIOD_INITIAL,
    UPDATE_CREDIT_PERIOD_REQUEST,
    UPDATE_CREDIT_PERIOD_SUCCESS,
    UPDATE_CREDIT_PERIOD_FAILURE,
    UPDATE_SERVICE_STATUS_REQUEST,
    UPDATE_SERVICE_STATUS_SUCCESS,
    UPDATE_SERVICE_STATUS_FAILURE

  } from '../DemoPages/Applications/Actions/enterpriseAccs';
  export default function enterpriseAccs(
    state = {
      isFetching: false,
    },
    action,
  ) {
    switch (action.type) {
      case CREATE_ENTERPRISEACC_INITIAL:
        return Object.assign({}, state, {
          isFetching: false,
          message: null,
        });
      case CREATE_ENTERPRISEACC_REQUEST:
        return Object.assign({}, state, {
          isFetching: true,
        });
      case CREATE_ENTERPRISEACC_SUCCESS:
        return Object.assign({}, state, {
          isFetching: false,
          message: 'Enterprise Account created successfully',
        });
      case CREATE_ENTERPRISEACC_FAILURE:
        return Object.assign({}, state, {
          isFetching: false,
          message:action.message,
        });
      case FETCH_ENTERPRISEACCSLIST_REQUEST:
        return Object.assign({}, state, {
          isFetching: true,
        });
      case FETCH_ENTERPRISEACCSLIST_SUCCESS:
        return Object.assign({}, state, {
          isFetching: false,
          enterpriseAccs: action.enterpriseAccs,
        });
      case FETCH_ENTERPRISEACCSLIST_FAILURE:
        return Object.assign({}, state, {
          isFetching: false,
          message: 'Something wrong happened. Please come back later',
        });

        case FETCH_ENTERPRISEACCS_REQUEST:
          return Object.assign({}, state, {
            isFetching: true,
          });
        case FETCH_ENTERPRISEACCS_SUCCESS:
          return Object.assign({}, state, {
            isFetching: false,
            enterpriseAcc: action.enterpriseAcc,
          });
        case FETCH_ENTERPRISEACCS_FAILURE:
          return Object.assign({}, state, {
            isFetching: false,
            message: 'Something wrong happened. Please come back later',
          });

          case CREATE_CLIENTKEYID_INITIAL:
            return Object.assign({}, state, {
              isFetching: false,
              message: null,
            });
          case CREATE_CLIENTKEYID_REQUEST:
            return Object.assign({}, state, {
              isFetching: true,
            });
          case CREATE_CLIENTKEYID_SUCCESS:
            return Object.assign({}, state, {
              isFetching: false,
              message: 'Client Id created',
              key:action.key
            });
          case CREATE_CLIENTKEYID_FAILURE:
            return Object.assign({}, state, {
              isFetching: false,
              message:
                'Something went wrong.',
            });
          case UPDATE_ENTERPRISEACC_INITIAL:
            return Object.assign({}, state, {
              isFetching: false,
              message: null,
            });
          case UPDATE_ENTERPRISEACC_REQUEST:
            return Object.assign({}, state, {
              isFetching: true,
            });
          case UPDATE_ENTERPRISEACC_SUCCESS:
            return Object.assign({}, state, {
              isFetching: false,
              message: 'Enterprise Account Updated successfully',
            });
          case UPDATE_ENTERPRISEACC_FAILURE:
            return Object.assign({}, state, {
              isFetching: false,
              message:
                'Due to security reasons enterprise account updation is closed.',
            });   
            case UPDATE_ENTERPRISEACC_PLAN_SUCCESS:
              return Object.assign({}, state, {
                isFetching: false,
                message: 'Enterprise Account Plan Updated successfully',
              });
            case UPDATE_ENTERPRISEACC_PLAN_FAILURE:
              return Object.assign({}, state, {
                isFetching: false,
                message:
                  'Error! Plan Updation failed',
              });  
              case FETCH_ENTERPRISE_BILL_REQUEST:
                return Object.assign({}, state, {
                  isFetching: true,
                });
              case FETCH_ENTERPRIS_BILL_SUCCESS:
                return Object.assign({}, state, {
                  isFetching: false,
                  enterpriseAccBills: action.entBills,
                });
              case FETCH_ENTERPRISE_BILL_FAILURE:
                return Object.assign({}, state, {
                  isFetching: false,
                  message: 'Something wrong happened. Please come back later',
                }); 
              case UPDATE_SERVICERATE_REQUEST:
                return Object.assign({}, state, {
                  updatingServiceRate: action.updatingServiceRate,
                  updateServiceRateErrorMessage:action.updateServiceRateErrorMessage,
                  updatedServicerate:action.updatedServicerate
                }); 
              case UPDATE_SERVICERATE_FAILURE:
                return Object.assign({}, state, {
                  updatingServiceRate: action.updatingServiceRate,
                  updateServiceRateErrorMessage:action.updateServiceRateErrorMessage,
                  updatedServicerate:action.updatedServicerate
                }); 
              case UPDATE_SERVICERATE_SUCCESS:
                return Object.assign({}, state, {
                  updatingServiceRate: action.updatingServiceRate,
                  updateServiceRateErrorMessage:action.updateServiceRateErrorMessage,
                  updatedServicerate:action.updatedServicerate
                }); 
              case UPDATE_SERVICERATE_INITIAL:
                return Object.assign({}, state, {
                  updatingServiceRate: action.updatingServiceRate,
                  updateServiceRateErrorMessage:action.updateServiceRateErrorMessage,
                  updatedServicerate:action.updatedServicerate
                }); 
              case UPDATE_CREDIT_PERIOD_INITIAL:
                return Object.assign({}, state, {
                  updatingCreditPeriod: action.updatingCreditPeriod,
                  updateCredtiPeriodEM:action.updateCredtiPeriodEM,
                  updatedCreditPeriod: action.updatedCreditPeriod
                }); 
              case UPDATE_CREDIT_PERIOD_REQUEST:
                return Object.assign({}, state, {
                  updatingCreditPeriod: action.updatingCreditPeriod,
                  updateCredtiPeriodEM:action.updateCredtiPeriodEM,
                  updatedCreditPeriod: action.updatedCreditPeriod
                }); 
              case UPDATE_CREDIT_PERIOD_SUCCESS:
                return Object.assign({}, state, {
                  updatingCreditPeriod: action.updatingCreditPeriod,
                  updateCredtiPeriodEM:action.updateCredtiPeriodEM,
                  updatedCreditPeriod: action.updatedCreditPeriod
                }); 
              case UPDATE_CREDIT_PERIOD_FAILURE:
                return Object.assign({}, state, {
                  updatingCreditPeriod: action.updatingCreditPeriod,
                  updateCredtiPeriodEM:action.updateCredtiPeriodEM,
                  updatedCreditPeriod: action.updatedCreditPeriod
                }); 
                case UPDATE_SERVICE_STATUS_REQUEST:
                  return Object.assign({}, state, {
                    updatingServiceStatus: action.updatingServiceStatus,
                    updateServiceStatusErrorMessage: action.updatingServiceStatus,
                    updatedServiceStatus: action.updatingServiceStatus
                  }); 
                  case UPDATE_SERVICE_STATUS_SUCCESS:
                    return Object.assign({}, state, {
                      updatingServiceStatus: action.updatingServiceStatus,
                      updateServiceStatusErrorMessage: action.updatingServiceStatus,
                      updatedServiceStatus: action.updatingServiceStatus
                    }); 
                    case UPDATE_SERVICE_STATUS_FAILURE:
                      return Object.assign({}, state, {
                        updatingServiceStatus: action.updatingServiceStatus,
                        updateServiceStatusErrorMessage: action.updatingServiceStatus,
                        updatedServiceStatus: action.updatingServiceStatus
                      }); 
      default:
        return state;
    }
  }  
  