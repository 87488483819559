export const UPLOAD_DOCS_INITIAL = 'UPLOAD_DOCS_INITIAL';
export const UPLOAD_DOCS_REQUEST = 'UPLOAD_DOCS_REQUEST';
export const UPLOAD_DOCS_SUCCESS = 'UPLOAD_DOCS_SUCCESS';
export const UPLOAD_DOCS_FAILURE = 'UPLOAD_DOCS_FAILURE';
export const FETCH_ENTERPRISEDOCS_REQUEST = 'FETCH_ENTERPRISEDOCS_REQUEST';
export const FETCH_ENTERPRISEDOCS_SUCCESS = 'FETCH_ENTERPRISEDOCS_SUCCESS';
export const FETCH_ENTERPRISEDOCS_FAILURE = 'FETCH_ENTERPRISEDOCS_FAILURE';

function createUploadDocsInitial() {
    return {
      type: UPLOAD_DOCS_INITIAL,
      isFetching: false,
    };
  }  
  function requestUploadDocs(status) {
    return {
      type: UPLOAD_DOCS_REQUEST,
      isFetching: true,
      status,
    };
  }
  
  function createUploadDocsSuccess(status) {
    return {
      type: UPLOAD_DOCS_SUCCESS,
      isFetching: false,
      status,
    };
  }
  
  function createUploadDocsError(message) {
    return {
      type: UPLOAD_DOCS_FAILURE,
      isFetching: false,
      message,
    };
  }
  
  function requestFetchEnterpriseDocs() {
    return {
      type: FETCH_ENTERPRISEDOCS_REQUEST,
      isFetching: true,
    };
  }
  
  function fetchEnterpriseDocsSuccess(entepriseDoc) {
    return {
      type: FETCH_ENTERPRISEDOCS_SUCCESS,
      isFetching: false,
      entepriseDoc,
    };
  }
  
  function fetchEnterpriseDocsError(message) {
    return {
      type: FETCH_ENTERPRISEDOCS_FAILURE,
      isFetching: false,
      message,
    };
  }

  export function uploadEnterpriseDocs(postData) {
    const config = {
     
      method: 'POST',
      
      body: JSON.stringify(postData),
      
    };
  
    return dispatch => {
      
      dispatch(requestUploadDocs(postData));     
     
      return fetch(process.env.REACT_APP_API_ENDPOINT+'/ent-kyc-docs', config)      
         .then(response => response.json()
         .then(responseJson => ({ enterpriseAccId:responseJson.enterpriseAccId,
           responseJson, })),)       
         .then(({ data, responseJson }) => {
          if (!responseJson.success) {
            dispatch(createUploadDocsError(responseJson.message));
            return Promise.reject(responseJson);
          }
          dispatch(createUploadDocsSuccess(responseJson));
          setTimeout(() => {
            dispatch(createUploadDocsInitial());
          }, 5000);
          return Promise.resolve(responseJson);
        })
        .catch(err => console.error('Error: ', err));
    };
  }
  
  // export function fetchEnterpriseAcc() {
  //   const config = {
  //     method: 'GET',
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json',
  //     },      
  //   };
  
  //   return dispatch => {
  //     dispatch(requestFetchEnterpriseDocs());  
  //     return fetch('https://2fv99k57nj.execute-api.ap-southeast-1.amazonaws.com/dev/enterprise-accs')
  //       .then(response =>
  //         response.json().then(responseJson => ({
  //           enterpriseAccs: responseJson.enterpriseAccs,
  //           responseJson,
  //         })),
  //       )
  //       .then(({ enterpriseAccs, responseJson }) => {
  //         if (!responseJson.enterpriseAccs) {            
  //           dispatch(fetchEnterpriseDocsError(enterpriseAccs.message));
  //           return Promise.reject(enterpriseAccs);
  //         } 
  //         dispatch(fetchEnterpriseDocsSuccess(enterpriseAccs));
  //         return Promise.resolve(enterpriseAccs);
  //       })
  //       .catch(err => console.error('Error: ', err));
  //   };
  // }
  
  export  function downloadEntDoc(postdata){
    const config = {     
      method: 'POST',    
      body: JSON.stringify(postdata),    
    };
    return dispatch => {      
      dispatch(requestFetchEnterpriseDocs(postdata));        
      return fetch(process.env.REACT_APP_API_ENDPOINT+'/ent-docs-fetch', config)    
         .then(response => response.json()
         .then(responseJson => ({ enterpriseAccId:responseJson.enterpriseAccId,
           responseJson, })),)     
         .then(({ data, responseJson }) => {
          if (!responseJson.doccontent) {
            dispatch(fetchEnterpriseDocsError(responseJson.message));
            return Promise.reject(responseJson);
          }
          dispatch(fetchEnterpriseDocsSuccess(responseJson.doccontent));        
          return Promise.resolve(responseJson);
        })
        .catch(err => console.error('Error: ', err));   
    };
  }