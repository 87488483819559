import {
    UPLOAD_DOCS_INITIAL,
    UPLOAD_DOCS_REQUEST,
    UPLOAD_DOCS_SUCCESS,
    UPLOAD_DOCS_FAILURE,
    FETCH_ENTERPRISEDOCS_REQUEST,
    FETCH_ENTERPRISEDOCS_SUCCESS,
    FETCH_ENTERPRISEDOCS_FAILURE,
  } from '../DemoPages/Applications/Actions/fileuploadActions';



  export default function uploadDocsReducers(
    state = {
      isFetching: false,
    },
    action,
  ) {
    switch (action.type) {
      case UPLOAD_DOCS_INITIAL:
        return Object.assign({}, state, {
          isFetching: false,
          message: null,
        });
      case UPLOAD_DOCS_REQUEST:
        return Object.assign({}, state, {
          isFetching: true,
        });
      case UPLOAD_DOCS_SUCCESS:
        return Object.assign({}, state, {
          isFetching: false,
          message: 'Enterprise Account created successfully',
        });
      case UPLOAD_DOCS_FAILURE:
        return Object.assign({}, state, {
          isFetching: false,
          message:
            'Due to security reasons enterprise account creation is closed.',
        });
      case FETCH_ENTERPRISEDOCS_REQUEST:
        return Object.assign({}, state, {
          isFetching: true,
        });
      case FETCH_ENTERPRISEDOCS_SUCCESS:
        return Object.assign({}, state, {
          isFetching: false,
          entDoc: action.entepriseDoc,
        });
      case FETCH_ENTERPRISEDOCS_FAILURE:
        return Object.assign({}, state, {
          isFetching: false,
          message: 'Something wrong happened. Please come back later',
        });
      default:
        return state;
    }
  }
  